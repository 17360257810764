/* tslint:disable */
/* eslint-disable */
/**
 * web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: contact@orcalabs.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActiveHaulsFilter = {
    Date: 'Date',
    GearGroup: 'GearGroup',
    SpeciesGroup: 'SpeciesGroup',
    VesselLength: 'VesselLength'
} as const;

export type ActiveHaulsFilter = typeof ActiveHaulsFilter[keyof typeof ActiveHaulsFilter];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActiveLandingFilter = {
    Date: 'Date',
    GearGroup: 'GearGroup',
    SpeciesGroup: 'SpeciesGroup',
    VesselLength: 'VesselLength'
} as const;

export type ActiveLandingFilter = typeof ActiveLandingFilter[keyof typeof ActiveLandingFilter];


/**
 * 
 * @export
 * @interface AisPosition
 */
export interface AisPosition {
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'cog'?: number | null;
    /**
     * 
     * @type {AisPositionDetails}
     * @memberof AisPosition
     */
    'det'?: AisPositionDetails | null;
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'lon': number;
    /**
     * 
     * @type {string}
     * @memberof AisPosition
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface AisPositionDetails
 */
export interface AisPositionDetails {
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'distanceToShore': number;
    /**
     * 
     * @type {boolean}
     * @memberof AisPositionDetails
     */
    'missingData': boolean;
    /**
     * 
     * @type {NavigationStatus}
     * @memberof AisPositionDetails
     */
    'navigationalStatus'?: NavigationStatus | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'rateOfTurn'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'speedOverGround'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'trueHeading'?: number | null;
}


/**
 * 
 * @export
 * @interface AisVessel
 */
export interface AisVessel {
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'callSign'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'destination'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'eta'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'imoNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'mmsi': number;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'shipLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'shipWidth'?: number | null;
}
/**
 * 
 * @export
 * @interface AisVmsArea
 */
export interface AisVmsArea {
    /**
     * 
     * @type {Array<AisVmsAreaCount>}
     * @memberof AisVmsArea
     */
    'counts': Array<AisVmsAreaCount>;
    /**
     * 
     * @type {number}
     * @memberof AisVmsArea
     */
    'numVessels': number;
}
/**
 * 
 * @export
 * @interface AisVmsAreaCount
 */
export interface AisVmsAreaCount {
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'lon': number;
}
/**
 * 
 * @export
 * @interface AisVmsPosition
 */
export interface AisVmsPosition {
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'cog'?: number | null;
    /**
     * 
     * @type {AisVmsPositionDetails}
     * @memberof AisVmsPosition
     */
    'det'?: AisVmsPositionDetails | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'lon': number;
    /**
     * 
     * @type {TripPositionLayerId}
     * @memberof AisVmsPosition
     */
    'prunedBy'?: TripPositionLayerId | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'speed'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AisVmsPosition
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'tripCumulativeFuelConsumption'?: number | null;
}


/**
 * 
 * @export
 * @interface AisVmsPositionDetails
 */
export interface AisVmsPositionDetails {
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'distanceToShore': number;
    /**
     * 
     * @type {boolean}
     * @memberof AisVmsPositionDetails
     */
    'missingData': boolean;
    /**
     * 
     * @type {NavigationStatus}
     * @memberof AisVmsPositionDetails
     */
    'navigationalStatus'?: NavigationStatus | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'rateOfTurn'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'trueHeading'?: number | null;
}


/**
 * 
 * @export
 * @interface AverageTripBenchmarks
 */
export interface AverageTripBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'fuel_consumption'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weight_per_distance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weight_per_fuel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weight_per_hour'?: number | null;
}
/**
 * 
 * @export
 * @interface Benchmark
 */
export interface Benchmark {
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    'average': number;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    'averageFollowers': number;
    /**
     * 
     * @type {Array<BenchmarkEntry>}
     * @memberof Benchmark
     */
    'recentTrips': Array<BenchmarkEntry>;
}
/**
 * 
 * @export
 * @interface BenchmarkEntry
 */
export interface BenchmarkEntry {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkEntry
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkEntry
     */
    'tripStart': string;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkEntry
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface Catch
 */
export interface Catch {
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'grossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'livingWeight': number;
    /**
     * 
     * @type {Quality}
     * @memberof Catch
     */
    'productQualityId': Quality;
    /**
     * 
     * @type {string}
     * @memberof Catch
     */
    'productQualityName': string;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'productWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'speciesFiskeridirId': number;
}


/**
 * 
 * @export
 * @interface CumulativeLandings
 */
export interface CumulativeLandings {
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'cumulativeWeight': number;
    /**
     * 
     * @type {string}
     * @memberof CumulativeLandings
     */
    'month': string;
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface CurrentTrip
 */
export interface CurrentTrip {
    /**
     * 
     * @type {string}
     * @memberof CurrentTrip
     */
    'departure': string;
    /**
     * 
     * @type {Array<FishingFacility>}
     * @memberof CurrentTrip
     */
    'fishingFacilities': Array<FishingFacility>;
    /**
     * 
     * @type {Array<Haul>}
     * @memberof CurrentTrip
     */
    'hauls': Array<Haul>;
    /**
     * 
     * @type {number}
     * @memberof CurrentTrip
     */
    'targetSpeciesFiskeridirId'?: number | null;
}
/**
 * 
 * @export
 * @interface DeleteFuelMeasurement
 */
export interface DeleteFuelMeasurement {
    /**
     * 
     * @type {string}
     * @memberof DeleteFuelMeasurement
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface Delivery
 */
export interface Delivery {
    /**
     * 
     * @type {Array<Catch>}
     * @memberof Delivery
     */
    'delivered': Array<Catch>;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalGrossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalLivingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalProductWeight': number;
}
/**
 * 
 * @export
 * @interface DeliveryPoint
 */
export interface DeliveryPoint {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'name'?: string | null;
}
/**
 * Auto-generated discriminant enum variants
 * @export
 * @enum {string}
 */

export const ErrorDiscriminants = {
    StartAfterEnd: 'StartAfterEnd',
    InvalidCallSign: 'InvalidCallSign',
    MissingBwFiskInfoProfile: 'MissingBwFiskInfoProfile',
    InvalidDateRange: 'InvalidDateRange',
    MissingDateRange: 'MissingDateRange',
    MissingMmsiOrCallSignOrTripId: 'MissingMmsiOrCallSignOrTripId',
    InsufficientPermissions: 'InsufficientPermissions',
    MissingJwt: 'MissingJWT',
    InvalidJwt: 'InvalidJWT',
    ParseJwt: 'ParseJWT',
    JwtDecode: 'JWTDecode',
    QueryPayload: 'QueryPayload',
    Unexpected: 'Unexpected'
} as const;

export type ErrorDiscriminants = typeof ErrorDiscriminants[keyof typeof ErrorDiscriminants];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'description': string;
    /**
     * 
     * @type {ErrorDiscriminants}
     * @memberof ErrorResponse
     */
    'error': ErrorDiscriminants;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FishingFacilitiesSorting = {
    Setup: 'Setup',
    Removed: 'Removed',
    LastChanged: 'LastChanged'
} as const;

export type FishingFacilitiesSorting = typeof FishingFacilitiesSorting[keyof typeof FishingFacilitiesSorting];


/**
 * 
 * @export
 * @interface FishingFacility
 */
export interface FishingFacility {
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'barentswatchVesselId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'callSign'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'contactEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'contactPhone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'fiskeridirVesselId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'geometryWkt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'imo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'lastChanged': string;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'mmsi'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'regNum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'removedProcessedTimestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'removedTimestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'sbrRegNum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'setupProcessedTimestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'setupTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolColor'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'toolCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolId': string;
    /**
     * 
     * @type {FishingFacilityToolType}
     * @memberof FishingFacility
     */
    'toolType': FishingFacilityToolType;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'vesselName'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FishingFacilityToolType = {
    Undefined: 'Undefined',
    Crabpot: 'Crabpot',
    Danpurseine: 'Danpurseine',
    Nets: 'Nets',
    Longline: 'Longline',
    Generic: 'Generic',
    Sensorbuoy: 'Sensorbuoy',
    Sensorcable: 'Sensorcable',
    Unknown: 'Unknown',
    Seismic: 'Seismic',
    Mooring: 'Mooring',
    PlannedCableLaying: 'PlannedCableLaying'
} as const;

export type FishingFacilityToolType = typeof FishingFacilityToolType[keyof typeof FishingFacilityToolType];


/**
 * 
 * @export
 * @interface FishingSpotPrediction
 */
export interface FishingSpotPrediction {
    /**
     * 
     * @type {string}
     * @memberof FishingSpotPrediction
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof FishingSpotPrediction
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof FishingSpotPrediction
     */
    'longitude': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingSpotPrediction
     */
    'speciesGroupId': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface FishingWeightPrediction
 */
export interface FishingWeightPrediction {
    /**
     * 
     * @type {string}
     * @memberof FishingWeightPrediction
     */
    'catchLocationId': string;
    /**
     * 
     * @type {string}
     * @memberof FishingWeightPrediction
     */
    'date': string;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingWeightPrediction
     */
    'speciesGroupId': SpeciesGroup;
    /**
     * 
     * @type {number}
     * @memberof FishingWeightPrediction
     */
    'weight': number;
}


/**
 * 
 * @export
 * @interface FiskeridirVessel
 */
export interface FiskeridirVessel {
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'buildingYear'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'callSign': string;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'engineBuildingYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'enginePower'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'grossTonnage1969'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'grossTonnageOther'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'length'?: number | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof FiskeridirVessel
     */
    'lengthGroupId': VesselLengthGroup;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'nationGroupId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'nationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'norwegianCountyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'norwegianMunicipalityId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'owner'?: string | null;
    /**
     * 
     * @type {Array<RegisterVesselOwner>}
     * @memberof FiskeridirVessel
     */
    'owners'?: Array<RegisterVesselOwner> | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'rebuildingYear'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'registrationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'vesselTypeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'width'?: number | null;
}


/**
 * 
 * @export
 * @interface FuelMeasurement
 */
export interface FuelMeasurement {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'barentswatchUserId': string;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'callSign': string;
    /**
     * 
     * @type {number}
     * @memberof FuelMeasurement
     */
    'fuel': number;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface FuelMeasurementBody
 */
export interface FuelMeasurementBody {
    /**
     * 
     * @type {number}
     * @memberof FuelMeasurementBody
     */
    'fuel': number;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementBody
     */
    'timestamp': string;
}
/**
 * Gear code definitions from Fiskedirektoratet.
 * @export
 * @enum {string}
 */

export const Gear = {
    Unknown: 'Unknown',
    UndefinedSeine: 'UndefinedSeine',
    PurseSeine: 'PurseSeine',
    BeachSeine: 'BeachSeine',
    PurseSeineWithLight: 'PurseSeineWithLight',
    BeachSeineWithLight: 'BeachSeineWithLight',
    UndefinedNet: 'UndefinedNet',
    DriftNet: 'DriftNet',
    GillNet: 'GillNet',
    UndefinedHookGear: 'UndefinedHookGear',
    FloatingLine: 'FloatingLine',
    OtherLines: 'OtherLines',
    Jig: 'Jig',
    DorgHarpSnik: 'DorgHarpSnik',
    AutoLine: 'AutoLine',
    UndefinedLobsterTrapAndFykeNets: 'UndefinedLobsterTrapAndFykeNets',
    FykeNets: 'FykeNets',
    LobsterTraps: 'LobsterTraps',
    WedgeSeine: 'WedgeSeine',
    OceanLobsterTraps: 'OceanLobsterTraps',
    HookNet: 'HookNet',
    UndefinedTrawling: 'UndefinedTrawling',
    BottomTrawl: 'BottomTrawl',
    BottomTrawlPair: 'BottomTrawlPair',
    MidwaterTrawl: 'MidwaterTrawl',
    MidwaterTrawlPair: 'MidwaterTrawlPair',
    ShrimpTrawl: 'ShrimpTrawl',
    BeamTrawl: 'BeamTrawl',
    CrayfishTrawl: 'CrayfishTrawl',
    DoubleTrawl: 'DoubleTrawl',
    TripleTrawl: 'TripleTrawl',
    DanishSeine: 'DanishSeine',
    Harpoon: 'Harpoon',
    BaskingSharkWhaleCannon: 'BaskingSharkWhaleCannon',
    BigHarpoon: 'BigHarpoon',
    Rifle: 'Rifle',
    Other: 'Other',
    ShellScrape: 'ShellScrape',
    HandNet: 'HandNet',
    KelpTrawl: 'KelpTrawl',
    SeaweedCutter: 'SeaweedCutter',
    HandPicking: 'HandPicking',
    ShellSucker: 'ShellSucker',
    FishFarming: 'FishFarming',
    Unspecified: 'Unspecified'
} as const;

export type Gear = typeof Gear[keyof typeof Gear];


/**
 * 
 * @export
 * @interface GearDetailed
 */
export interface GearDetailed {
    /**
     * 
     * @type {Gear}
     * @memberof GearDetailed
     */
    'id': Gear;
    /**
     * 
     * @type {string}
     * @memberof GearDetailed
     */
    'name': string;
}


/**
 * GearGroup code definitions from Fiskedirektoratet.
 * @export
 * @enum {string}
 */

export const GearGroup = {
    Unknown: 'Unknown',
    Seine: 'Seine',
    Net: 'Net',
    HookGear: 'HookGear',
    LobsterTrapAndFykeNets: 'LobsterTrapAndFykeNets',
    Trawl: 'Trawl',
    DanishSeine: 'DanishSeine',
    HarpoonCannon: 'HarpoonCannon',
    OtherGear: 'OtherGear',
    FishFarming: 'FishFarming'
} as const;

export type GearGroup = typeof GearGroup[keyof typeof GearGroup];


/**
 * 
 * @export
 * @interface GearGroupDetailed
 */
export interface GearGroupDetailed {
    /**
     * 
     * @type {GearGroup}
     * @memberof GearGroupDetailed
     */
    'id': GearGroup;
    /**
     * 
     * @type {string}
     * @memberof GearGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface GearMainGroupDetailed
 */
export interface GearMainGroupDetailed {
    /**
     * 
     * @type {MainGearGroup}
     * @memberof GearMainGroupDetailed
     */
    'id': MainGearGroup;
    /**
     * 
     * @type {string}
     * @memberof GearMainGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface Haul
 */
export interface Haul {
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'callSign': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Haul
     */
    'catchLocations'?: Array<string> | null;
    /**
     * 
     * @type {Array<HaulCatch>}
     * @memberof Haul
     */
    'catches': Array<HaulCatch>;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'fiskeridirVesselId'?: number | null;
    /**
     * 
     * @type {Gear}
     * @memberof Haul
     */
    'gear': Gear;
    /**
     * 
     * @type {GearGroup}
     * @memberof Haul
     */
    'gearGroupId': GearGroup;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'haulDistance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'haulId': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'startLatitude': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'startLongitude': number;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'startTimestamp': string;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'stopLatitude': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'stopLongitude': number;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'stopTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'vesselName'?: string | null;
}


/**
 * 
 * @export
 * @interface HaulCatch
 */
export interface HaulCatch {
    /**
     * 
     * @type {number}
     * @memberof HaulCatch
     */
    'livingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof HaulCatch
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof HaulCatch
     */
    'speciesGroupId': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface HaulsMatrix
 */
export interface HaulsMatrix {
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'dates': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'gearGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'lengthGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'speciesGroup': Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HaulsSorting = {
    StartDate: 'StartDate',
    StopDate: 'StopDate',
    Weight: 'Weight'
} as const;

export type HaulsSorting = typeof HaulsSorting[keyof typeof HaulsSorting];


/**
 * 
 * @export
 * @interface Landing
 */
export interface Landing {
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'catchLocation'?: string | null;
    /**
     * 
     * @type {Array<LandingCatch>}
     * @memberof Landing
     */
    'catches': Array<LandingCatch>;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'deliveryPointId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'fiskeridirVesselId'?: number | null;
    /**
     * 
     * @type {GearGroup}
     * @memberof Landing
     */
    'gearGroupId': GearGroup;
    /**
     * 
     * @type {Gear}
     * @memberof Landing
     */
    'gearId': Gear;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'landingId': string;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'landingTimestamp': string;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalGrossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalLivingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalProductWeight': number;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'vesselCallSign'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'vesselLength'?: number | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof Landing
     */
    'vesselLengthGroup': VesselLengthGroup;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'vesselName'?: string | null;
}


/**
 * 
 * @export
 * @interface LandingCatch
 */
export interface LandingCatch {
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'grossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'livingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'productWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof LandingCatch
     */
    'speciesGroupId': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface LandingMatrix
 */
export interface LandingMatrix {
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'dates': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'gearGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'lengthGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'speciesGroup': Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LandingsSorting = {
    LandingTimestamp: 'LandingTimestamp',
    LivingWeight: 'LivingWeight'
} as const;

export type LandingsSorting = typeof LandingsSorting[keyof typeof LandingsSorting];


/**
 * MainGearGroup code definitions from Fiskedirektoratet.
 * @export
 * @enum {string}
 */

export const MainGearGroup = {
    Unknown: 'Unknown',
    Trawl: 'Trawl',
    Seine: 'Seine',
    Conventional: 'Conventional',
    Other: 'Other'
} as const;

export type MainGearGroup = typeof MainGearGroup[keyof typeof MainGearGroup];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelId = {
    Spot: 'Spot',
    Weight: 'Weight',
    WeightWeather: 'WeightWeather',
    SpotWeather: 'SpotWeather'
} as const;

export type ModelId = typeof ModelId[keyof typeof ModelId];


/**
 * 
 * @export
 * @enum {string}
 */

export const NavigationStatus = {
    UnderWayUsingEngine: 'UnderWayUsingEngine',
    AtAnchor: 'AtAnchor',
    NotUnderCommand: 'NotUnderCommand',
    RestrictedManoeuverability: 'RestrictedManoeuverability',
    ConstrainedByDraught: 'ConstrainedByDraught',
    Moored: 'Moored',
    Aground: 'Aground',
    EngagedInFishing: 'EngagedInFishing',
    UnderWaySailing: 'UnderWaySailing',
    Reserved9: 'Reserved9',
    Reserved10: 'Reserved10',
    Reserved11: 'Reserved11',
    Reserved12: 'Reserved12',
    Reserved13: 'Reserved13',
    AisSartIsActive: 'AisSartIsActive',
    NotDefined: 'NotDefined'
} as const;

export type NavigationStatus = typeof NavigationStatus[keyof typeof NavigationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const Ordering = {
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type Ordering = typeof Ordering[keyof typeof Ordering];


/**
 * 
 * @export
 * @enum {string}
 */

export const Quality = {
    Extra: 'Extra',
    Prima: 'Prima',
    Superior: 'Superior',
    A: 'A',
    Blank: 'Blank',
    B: 'B',
    Sekunda: 'Sekunda',
    Africa: 'Africa',
    FrostDamagedFish: 'FrostDamagedFish',
    Yellow: 'Yellow',
    ProductionRoe: 'ProductionRoe',
    CrackedCrab: 'CrackedCrab',
    WetCrab: 'WetCrab',
    WrongCut: 'WrongCut',
    Injured: 'Injured',
    Offal: 'Offal',
    Wrek: 'Wrek',
    Unspecified: 'Unspecified'
} as const;

export type Quality = typeof Quality[keyof typeof Quality];


/**
 * 
 * @export
 * @enum {string}
 */

export const RegisterVesselEntityType = {
    Company: 'COMPANY',
    Person: 'PERSON'
} as const;

export type RegisterVesselEntityType = typeof RegisterVesselEntityType[keyof typeof RegisterVesselEntityType];


/**
 * 
 * @export
 * @interface RegisterVesselOwner
 */
export interface RegisterVesselOwner {
    /**
     * 
     * @type {string}
     * @memberof RegisterVesselOwner
     */
    'city'?: string | null;
    /**
     * 
     * @type {RegisterVesselEntityType}
     * @memberof RegisterVesselOwner
     */
    'entityType': RegisterVesselEntityType;
    /**
     * 
     * @type {number}
     * @memberof RegisterVesselOwner
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterVesselOwner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RegisterVesselOwner
     */
    'postalCode': number;
}


/**
 * 
 * @export
 * @interface Species
 */
export interface Species {
    /**
     * 
     * @type {number}
     * @memberof Species
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Species
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SpeciesFao
 */
export interface SpeciesFao {
    /**
     * 
     * @type {string}
     * @memberof SpeciesFao
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SpeciesFao
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface SpeciesFiskeridir
 */
export interface SpeciesFiskeridir {
    /**
     * 
     * @type {number}
     * @memberof SpeciesFiskeridir
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpeciesFiskeridir
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SpeciesGroup = {
    Unknown: 'Unknown',
    Capelin: 'Capelin',
    NorwegianSpringSpawningHerring: 'NorwegianSpringSpawningHerring',
    OtherHerring: 'OtherHerring',
    Mackerel: 'Mackerel',
    BlueWhiting: 'BlueWhiting',
    NorwayPout: 'NorwayPout',
    Sandeels: 'Sandeels',
    Argentines: 'Argentines',
    EuropeanSpratSea: 'EuropeanSpratSea',
    EuropeanSpratCoast: 'EuropeanSpratCoast',
    MesopelagicFish: 'MesopelagicFish',
    TunaAndTunaishSpecies: 'TunaAndTunaishSpecies',
    OtherPelagicFish: 'OtherPelagicFish',
    AtlanticCod: 'AtlanticCod',
    Haddock: 'Haddock',
    Saithe: 'Saithe',
    Gadiformes: 'Gadiformes',
    GreenlandHalibut: 'GreenlandHalibut',
    GoldenRedfish: 'GoldenRedfish',
    Wrasse: 'Wrasse',
    Wolffishes: 'Wolffishes',
    FlatFishOtherBottomFishAndDeepseaFish: 'FlatFishOtherBottomFishAndDeepseaFish',
    SharkFish: 'SharkFish',
    SkatesAndOtherChondrichthyes: 'SkatesAndOtherChondrichthyes',
    QueenCrab: 'QueenCrab',
    EdibleCrab: 'EdibleCrab',
    RedKingCrab: 'RedKingCrab',
    RedKingCrabOther: 'RedKingCrabOther',
    NorthernPrawn: 'NorthernPrawn',
    AntarcticKrill: 'AntarcticKrill',
    CalanusFinmarchicus: 'CalanusFinmarchicus',
    OtherShellfishMolluscaAndEchinoderm: 'OtherShellfishMolluscaAndEchinoderm',
    BrownSeaweed: 'BrownSeaweed',
    OtherSeaweed: 'OtherSeaweed',
    FreshWaterFish: 'FreshWaterFish',
    FishFarming: 'FishFarming',
    MarineMammals: 'MarineMammals',
    Other: 'Other'
} as const;

export type SpeciesGroup = typeof SpeciesGroup[keyof typeof SpeciesGroup];


/**
 * 
 * @export
 * @interface SpeciesGroupDetailed
 */
export interface SpeciesGroupDetailed {
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof SpeciesGroupDetailed
     */
    'id': SpeciesGroup;
    /**
     * 
     * @type {string}
     * @memberof SpeciesGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SpeciesMainGroup = {
    Unknown: 'Unknown',
    PelagicFish: 'PelagicFish',
    CodAndCodishFish: 'CodAndCodishFish',
    FlatFishOtherBottomFishAndDeepseaFish: 'FlatFishOtherBottomFishAndDeepseaFish',
    ChondrichthyesSharkFishSkatesRaysAndRabbitFish: 'ChondrichthyesSharkFishSkatesRaysAndRabbitFish',
    ShellfishMolluscaAndEchinoderm: 'ShellfishMolluscaAndEchinoderm',
    Seaweed: 'Seaweed',
    FishFarmingFreshWaterFishAndMarineMammals: 'FishFarmingFreshWaterFishAndMarineMammals'
} as const;

export type SpeciesMainGroup = typeof SpeciesMainGroup[keyof typeof SpeciesMainGroup];


/**
 * 
 * @export
 * @interface SpeciesMainGroupDetailed
 */
export interface SpeciesMainGroupDetailed {
    /**
     * 
     * @type {SpeciesMainGroup}
     * @memberof SpeciesMainGroupDetailed
     */
    'id': SpeciesMainGroup;
    /**
     * 
     * @type {string}
     * @memberof SpeciesMainGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {Delivery}
     * @memberof Trip
     */
    'delivery': Delivery;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    'deliveryPointIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'endPortId'?: string | null;
    /**
     * 
     * @type {Array<VesselEvent>}
     * @memberof Trip
     */
    'events': Array<VesselEvent>;
    /**
     * 
     * @type {Array<FishingFacility>}
     * @memberof Trip
     */
    'fishingFacilities': Array<FishingFacility>;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'fuelConsumption'?: number | null;
    /**
     * 
     * @type {Array<Gear>}
     * @memberof Trip
     */
    'gearIds': Array<Gear>;
    /**
     * 
     * @type {Array<Haul>}
     * @memberof Trip
     */
    'hauls': Array<Haul>;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'landingCoverageEnd': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'landingCoverageStart': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    'landingIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'mostRecentDeliveryDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'numDeliveries': number;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'startPortId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'targetSpeciesFaoId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'targetSpeciesFiskeridirId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'trackCoverage'?: number | null;
    /**
     * 
     * @type {TripAssemblerId}
     * @memberof Trip
     */
    'tripAssemblerId': TripAssemblerId;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'tripId': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TripAssemblerId = {
    Landings: 'Landings',
    Ers: 'Ers'
} as const;

export type TripAssemblerId = typeof TripAssemblerId[keyof typeof TripAssemblerId];


/**
 * 
 * @export
 * @interface TripBenchmark
 */
export interface TripBenchmark {
    /**
     * 
     * @type {string}
     * @memberof TripBenchmark
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'fuelConsumption'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TripBenchmark
     */
    'start': string;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerDistance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerFuel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerHour'?: number | null;
}
/**
 * 
 * @export
 * @interface TripBenchmarks
 */
export interface TripBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'fuelConsumption'?: number | null;
    /**
     * 
     * @type {Array<TripBenchmark>}
     * @memberof TripBenchmarks
     */
    'trips': Array<TripBenchmark>;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerDistance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerFuel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerHour'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TripPositionLayerId = {
    UnrealisticSpeed: 'UnrealisticSpeed',
    Cluster: 'Cluster',
    AisVmsConflict: 'AisVmsConflict'
} as const;

export type TripPositionLayerId = typeof TripPositionLayerId[keyof typeof TripPositionLayerId];


/**
 * 
 * @export
 * @enum {string}
 */

export const TripSorting = {
    StopDate: 'StopDate',
    Weight: 'Weight'
} as const;

export type TripSorting = typeof TripSorting[keyof typeof TripSorting];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<number>}
     * @memberof User
     */
    'following': Array<number>;
}
/**
 * 
 * @export
 * @interface Vessel
 */
export interface Vessel {
    /**
     * 
     * @type {AisVessel}
     * @memberof Vessel
     */
    'ais'?: AisVessel | null;
    /**
     * 
     * @type {FiskeridirVessel}
     * @memberof Vessel
     */
    'fiskeridir': FiskeridirVessel;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof Vessel
     */
    'gearGroups': Array<GearGroup>;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof Vessel
     */
    'speciesGroups': Array<SpeciesGroup>;
}
/**
 * 
 * @export
 * @interface VesselBenchmarks
 */
export interface VesselBenchmarks {
    /**
     * 
     * @type {Array<CumulativeLandings>}
     * @memberof VesselBenchmarks
     */
    'cumulativeLandings': Array<CumulativeLandings>;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'ersDca'?: Benchmark | null;
    /**
     * Distance unit is in meters
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'fishingDistance'?: Benchmark | null;
    /**
     * Time unit is in minutes
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'fishingTime'?: Benchmark | null;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'landings'?: Benchmark | null;
    /**
     * Time unit is in minutes
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'tripTime'?: Benchmark | null;
}
/**
 * 
 * @export
 * @interface VesselEvent
 */
export interface VesselEvent {
    /**
     * 
     * @type {number}
     * @memberof VesselEvent
     */
    'eventId': number;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'eventName': string;
    /**
     * 
     * @type {VesselEventType}
     * @memberof VesselEvent
     */
    'eventType': VesselEventType;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'occurenceTimestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'reportTimestamp': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselEventType = {
    Landing: 'Landing',
    ErsDca: 'ErsDca',
    ErsPor: 'ErsPor',
    ErsDep: 'ErsDep',
    ErsTra: 'ErsTra',
    Haul: 'Haul'
} as const;

export type VesselEventType = typeof VesselEventType[keyof typeof VesselEventType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselLengthGroup = {
    Unknown: 'Unknown',
    UnderEleven: 'UnderEleven',
    ElevenToFifteen: 'ElevenToFifteen',
    FifteenToTwentyOne: 'FifteenToTwentyOne',
    TwentyTwoToTwentyEight: 'TwentyTwoToTwentyEight',
    TwentyEightAndAbove: 'TwentyEightAndAbove'
} as const;

export type VesselLengthGroup = typeof VesselLengthGroup[keyof typeof VesselLengthGroup];


/**
 * 
 * @export
 * @interface VmsPosition
 */
export interface VmsPosition {
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'course'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'distanceToShore': number;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'lon': number;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'speed'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VmsPosition
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface Weather
 */
export interface Weather {
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'airPressureAtSeaLevel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'airTemperature2m'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'altitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'cloudAreaFraction'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'landAreaFraction': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'precipitationAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'relativeHumidity2m'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Weather
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'windDirection10m'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'windSpeed10m'?: number | null;
}
/**
 * 
 * @export
 * @interface WeatherLocation
 */
export interface WeatherLocation {
    /**
     * 
     * @type {number}
     * @memberof WeatherLocation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WeatherLocation
     */
    'polygon': string;
}

/**
 * V1aisApi - axios parameter creator
 * @export
 */
export const V1aisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [positionTimestampLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisCurrentPositions: async (positionTimestampLimit?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/ais_current_positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["read:ais:under_15m"], configuration)

            if (positionTimestampLimit !== undefined) {
                localVarQueryParameter['positionTimestampLimit'] = (positionTimestampLimit as any instanceof Date) ?
                    (positionTimestampLimit as any).toISOString() :
                    positionTimestampLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mmsi 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisTrack: async (mmsi: number, start?: string | null, end?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mmsi' is not null or undefined
            assertParamExists('aisTrack', 'mmsi', mmsi)
            const localVarPath = `/v1.0/ais_track/{mmsi}`
                .replace(`{${"mmsi"}}`, encodeURIComponent(String(mmsi)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["read:ais:under_15m"], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1aisApi - functional programming interface
 * @export
 */
export const V1aisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1aisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [positionTimestampLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aisCurrentPositions(positionTimestampLimit?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aisCurrentPositions(positionTimestampLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1aisApi.aisCurrentPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} mmsi 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aisTrack(mmsi: number, start?: string | null, end?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aisTrack(mmsi, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1aisApi.aisTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1aisApi - factory interface
 * @export
 */
export const V1aisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1aisApiFp(configuration)
    return {
        /**
         * 
         * @param {V1aisApiAisCurrentPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisCurrentPositions(requestParameters: V1aisApiAisCurrentPositionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AisPosition>> {
            return localVarFp.aisCurrentPositions(requestParameters.positionTimestampLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1aisApiAisTrackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisTrack(requestParameters: V1aisApiAisTrackRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AisPosition>> {
            return localVarFp.aisTrack(requestParameters.mmsi, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aisCurrentPositions operation in V1aisApi.
 * @export
 * @interface V1aisApiAisCurrentPositionsRequest
 */
export interface V1aisApiAisCurrentPositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof V1aisApiAisCurrentPositions
     */
    readonly positionTimestampLimit?: string | null
}

/**
 * Request parameters for aisTrack operation in V1aisApi.
 * @export
 * @interface V1aisApiAisTrackRequest
 */
export interface V1aisApiAisTrackRequest {
    /**
     * 
     * @type {number}
     * @memberof V1aisApiAisTrack
     */
    readonly mmsi: number

    /**
     * 
     * @type {string}
     * @memberof V1aisApiAisTrack
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1aisApiAisTrack
     */
    readonly end?: string | null
}

/**
 * V1aisApi - object-oriented interface
 * @export
 * @class V1aisApi
 * @extends {BaseAPI}
 */
export class V1aisApi extends BaseAPI {
    /**
     * 
     * @param {V1aisApiAisCurrentPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1aisApi
     */
    public aisCurrentPositions(requestParameters: V1aisApiAisCurrentPositionsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1aisApiFp(this.configuration).aisCurrentPositions(requestParameters.positionTimestampLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1aisApiAisTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1aisApi
     */
    public aisTrack(requestParameters: V1aisApiAisTrackRequest, options?: RawAxiosRequestConfig) {
        return V1aisApiFp(this.configuration).aisTrack(requestParameters.mmsi, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1aisVmsApi - axios parameter creator
 * @export
 */
export const V1aisVmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} x1 
         * @param {number} x2 
         * @param {number} y1 
         * @param {number} y2 
         * @param {string | null} [dateLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisVmsArea: async (x1: number, x2: number, y1: number, y2: number, dateLimit?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'x1' is not null or undefined
            assertParamExists('aisVmsArea', 'x1', x1)
            // verify required parameter 'x2' is not null or undefined
            assertParamExists('aisVmsArea', 'x2', x2)
            // verify required parameter 'y1' is not null or undefined
            assertParamExists('aisVmsArea', 'y1', y1)
            // verify required parameter 'y2' is not null or undefined
            assertParamExists('aisVmsArea', 'y2', y2)
            const localVarPath = `/v1.0/ais_vms_area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (x1 !== undefined) {
                localVarQueryParameter['x1'] = x1;
            }

            if (x2 !== undefined) {
                localVarQueryParameter['x2'] = x2;
            }

            if (y1 !== undefined) {
                localVarQueryParameter['y1'] = y1;
            }

            if (y2 !== undefined) {
                localVarQueryParameter['y2'] = y2;
            }

            if (dateLimit !== undefined) {
                localVarQueryParameter['dateLimit'] = (dateLimit as any instanceof Date) ?
                    (dateLimit as any).toISOString().substring(0,10) :
                    dateLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number | null} [mmsi] 
         * @param {string | null} [callSign] 
         * @param {number | null} [tripId] 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisVmsPositions: async (mmsi?: number | null, callSign?: string | null, tripId?: number | null, start?: string | null, end?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/ais_vms_positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["read:ais:under_15m"], configuration)

            if (mmsi !== undefined) {
                localVarQueryParameter['mmsi'] = mmsi;
            }

            if (callSign !== undefined) {
                localVarQueryParameter['callSign'] = callSign;
            }

            if (tripId !== undefined) {
                localVarQueryParameter['tripId'] = tripId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1aisVmsApi - functional programming interface
 * @export
 */
export const V1aisVmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1aisVmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} x1 
         * @param {number} x2 
         * @param {number} y1 
         * @param {number} y2 
         * @param {string | null} [dateLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aisVmsArea(x1: number, x2: number, y1: number, y2: number, dateLimit?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AisVmsArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aisVmsArea(x1, x2, y1, y2, dateLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1aisVmsApi.aisVmsArea']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number | null} [mmsi] 
         * @param {string | null} [callSign] 
         * @param {number | null} [tripId] 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aisVmsPositions(mmsi?: number | null, callSign?: string | null, tripId?: number | null, start?: string | null, end?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisVmsPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aisVmsPositions(mmsi, callSign, tripId, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1aisVmsApi.aisVmsPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1aisVmsApi - factory interface
 * @export
 */
export const V1aisVmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1aisVmsApiFp(configuration)
    return {
        /**
         * 
         * @param {V1aisVmsApiAisVmsAreaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisVmsArea(requestParameters: V1aisVmsApiAisVmsAreaRequest, options?: RawAxiosRequestConfig): AxiosPromise<AisVmsArea> {
            return localVarFp.aisVmsArea(requestParameters.x1, requestParameters.x2, requestParameters.y1, requestParameters.y2, requestParameters.dateLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1aisVmsApiAisVmsPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aisVmsPositions(requestParameters: V1aisVmsApiAisVmsPositionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AisVmsPosition>> {
            return localVarFp.aisVmsPositions(requestParameters.mmsi, requestParameters.callSign, requestParameters.tripId, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aisVmsArea operation in V1aisVmsApi.
 * @export
 * @interface V1aisVmsApiAisVmsAreaRequest
 */
export interface V1aisVmsApiAisVmsAreaRequest {
    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsArea
     */
    readonly x1: number

    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsArea
     */
    readonly x2: number

    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsArea
     */
    readonly y1: number

    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsArea
     */
    readonly y2: number

    /**
     * 
     * @type {string}
     * @memberof V1aisVmsApiAisVmsArea
     */
    readonly dateLimit?: string | null
}

/**
 * Request parameters for aisVmsPositions operation in V1aisVmsApi.
 * @export
 * @interface V1aisVmsApiAisVmsPositionsRequest
 */
export interface V1aisVmsApiAisVmsPositionsRequest {
    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsPositions
     */
    readonly mmsi?: number | null

    /**
     * 
     * @type {string}
     * @memberof V1aisVmsApiAisVmsPositions
     */
    readonly callSign?: string | null

    /**
     * 
     * @type {number}
     * @memberof V1aisVmsApiAisVmsPositions
     */
    readonly tripId?: number | null

    /**
     * 
     * @type {string}
     * @memberof V1aisVmsApiAisVmsPositions
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1aisVmsApiAisVmsPositions
     */
    readonly end?: string | null
}

/**
 * V1aisVmsApi - object-oriented interface
 * @export
 * @class V1aisVmsApi
 * @extends {BaseAPI}
 */
export class V1aisVmsApi extends BaseAPI {
    /**
     * 
     * @param {V1aisVmsApiAisVmsAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1aisVmsApi
     */
    public aisVmsArea(requestParameters: V1aisVmsApiAisVmsAreaRequest, options?: RawAxiosRequestConfig) {
        return V1aisVmsApiFp(this.configuration).aisVmsArea(requestParameters.x1, requestParameters.x2, requestParameters.y1, requestParameters.y2, requestParameters.dateLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1aisVmsApiAisVmsPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1aisVmsApi
     */
    public aisVmsPositions(requestParameters: V1aisVmsApiAisVmsPositionsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1aisVmsApiFp(this.configuration).aisVmsPositions(requestParameters.mmsi, requestParameters.callSign, requestParameters.tripId, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1deliveryPointApi - axios parameter creator
 * @export
 */
export const V1deliveryPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPoints: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/delivery_points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1deliveryPointApi - functional programming interface
 * @export
 */
export const V1deliveryPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1deliveryPointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryPoints(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryPoint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryPoints(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1deliveryPointApi.deliveryPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1deliveryPointApi - factory interface
 * @export
 */
export const V1deliveryPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1deliveryPointApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPoints(options?: RawAxiosRequestConfig): AxiosPromise<Array<DeliveryPoint>> {
            return localVarFp.deliveryPoints(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1deliveryPointApi - object-oriented interface
 * @export
 * @class V1deliveryPointApi
 * @extends {BaseAPI}
 */
export class V1deliveryPointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1deliveryPointApi
     */
    public deliveryPoints(options?: RawAxiosRequestConfig) {
        return V1deliveryPointApiFp(this.configuration).deliveryPoints(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1fishingFacilityApi - axios parameter creator
 * @export
 */
export const V1fishingFacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number> | null} [mmsis] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {Array<FishingFacilityToolType> | null} [toolTypes] 
         * @param {boolean | null} [active] 
         * @param {Array<string> | null} [setupRanges] 
         * @param {Array<string> | null} [removedRanges] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {FishingFacilitiesSorting | null} [sorting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingFacilities: async (mmsis?: Array<number> | null, fiskeridirVesselIds?: Array<number> | null, toolTypes?: Array<FishingFacilityToolType> | null, active?: boolean | null, setupRanges?: Array<string> | null, removedRanges?: Array<string> | null, limit?: number | null, offset?: number | null, ordering?: Ordering | null, sorting?: FishingFacilitiesSorting | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/fishing_facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (mmsis) {
                localVarQueryParameter['mmsis[]'] = mmsis;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (toolTypes) {
                localVarQueryParameter['toolTypes[]'] = toolTypes;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (setupRanges) {
                localVarQueryParameter['setupRanges[]'] = setupRanges;
            }

            if (removedRanges) {
                localVarQueryParameter['removedRanges[]'] = removedRanges;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1fishingFacilityApi - functional programming interface
 * @export
 */
export const V1fishingFacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1fishingFacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number> | null} [mmsis] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {Array<FishingFacilityToolType> | null} [toolTypes] 
         * @param {boolean | null} [active] 
         * @param {Array<string> | null} [setupRanges] 
         * @param {Array<string> | null} [removedRanges] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {FishingFacilitiesSorting | null} [sorting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fishingFacilities(mmsis?: Array<number> | null, fiskeridirVesselIds?: Array<number> | null, toolTypes?: Array<FishingFacilityToolType> | null, active?: boolean | null, setupRanges?: Array<string> | null, removedRanges?: Array<string> | null, limit?: number | null, offset?: number | null, ordering?: Ordering | null, sorting?: FishingFacilitiesSorting | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingFacility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fishingFacilities(mmsis, fiskeridirVesselIds, toolTypes, active, setupRanges, removedRanges, limit, offset, ordering, sorting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fishingFacilityApi.fishingFacilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1fishingFacilityApi - factory interface
 * @export
 */
export const V1fishingFacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1fishingFacilityApiFp(configuration)
    return {
        /**
         * 
         * @param {V1fishingFacilityApiFishingFacilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingFacilities(requestParameters: V1fishingFacilityApiFishingFacilitiesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingFacility>> {
            return localVarFp.fishingFacilities(requestParameters.mmsis, requestParameters.fiskeridirVesselIds, requestParameters.toolTypes, requestParameters.active, requestParameters.setupRanges, requestParameters.removedRanges, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.sorting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fishingFacilities operation in V1fishingFacilityApi.
 * @export
 * @interface V1fishingFacilityApiFishingFacilitiesRequest
 */
export interface V1fishingFacilityApiFishingFacilitiesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly mmsis?: Array<number> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {Array<FishingFacilityToolType>}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly toolTypes?: Array<FishingFacilityToolType> | null

    /**
     * 
     * @type {boolean}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly active?: boolean | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly setupRanges?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly removedRanges?: Array<string> | null

    /**
     * 
     * @type {number}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly offset?: number | null

    /**
     * 
     * @type {Ordering}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {FishingFacilitiesSorting}
     * @memberof V1fishingFacilityApiFishingFacilities
     */
    readonly sorting?: FishingFacilitiesSorting | null
}

/**
 * V1fishingFacilityApi - object-oriented interface
 * @export
 * @class V1fishingFacilityApi
 * @extends {BaseAPI}
 */
export class V1fishingFacilityApi extends BaseAPI {
    /**
     * 
     * @param {V1fishingFacilityApiFishingFacilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fishingFacilityApi
     */
    public fishingFacilities(requestParameters: V1fishingFacilityApiFishingFacilitiesRequest = {}, options?: RawAxiosRequestConfig) {
        return V1fishingFacilityApiFp(this.configuration).fishingFacilities(requestParameters.mmsis, requestParameters.fiskeridirVesselIds, requestParameters.toolTypes, requestParameters.active, requestParameters.setupRanges, requestParameters.removedRanges, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.sorting, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1fishingPredictionApi - axios parameter creator
 * @export
 */
export const V1fishingPredictionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFishingSpotPredictions: async (modelId: ModelId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('allFishingSpotPredictions', 'modelId', modelId)
            const localVarPath = `/v1.0/fishing_spot_predictions/{model_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFishingWeightPredictions: async (modelId: ModelId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('allFishingWeightPredictions', 'modelId', modelId)
            const localVarPath = `/v1.0/fishing_weight_predictions/{model_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingSpotPredictions: async (modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('fishingSpotPredictions', 'modelId', modelId)
            // verify required parameter 'speciesGroupId' is not null or undefined
            assertParamExists('fishingSpotPredictions', 'speciesGroupId', speciesGroupId)
            const localVarPath = `/v1.0/fishing_spot_predictions/{model_id}/{species_group_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"species_group_id"}}`, encodeURIComponent(String(speciesGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {number | null} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingWeightPredictions: async (modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, limit?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('fishingWeightPredictions', 'modelId', modelId)
            // verify required parameter 'speciesGroupId' is not null or undefined
            assertParamExists('fishingWeightPredictions', 'speciesGroupId', speciesGroupId)
            const localVarPath = `/v1.0/fishing_weight_predictions/{model_id}/{species_group_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"species_group_id"}}`, encodeURIComponent(String(speciesGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1fishingPredictionApi - functional programming interface
 * @export
 */
export const V1fishingPredictionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1fishingPredictionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allFishingSpotPredictions(modelId: ModelId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingSpotPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allFishingSpotPredictions(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fishingPredictionApi.allFishingSpotPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allFishingWeightPredictions(modelId: ModelId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingWeightPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allFishingWeightPredictions(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fishingPredictionApi.allFishingWeightPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fishingSpotPredictions(modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FishingSpotPrediction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fishingSpotPredictions(modelId, speciesGroupId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fishingPredictionApi.fishingSpotPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {number | null} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fishingWeightPredictions(modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, limit?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingWeightPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fishingWeightPredictions(modelId, speciesGroupId, date, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fishingPredictionApi.fishingWeightPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1fishingPredictionApi - factory interface
 * @export
 */
export const V1fishingPredictionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1fishingPredictionApiFp(configuration)
    return {
        /**
         * 
         * @param {V1fishingPredictionApiAllFishingSpotPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFishingSpotPredictions(requestParameters: V1fishingPredictionApiAllFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingSpotPrediction>> {
            return localVarFp.allFishingSpotPredictions(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fishingPredictionApiAllFishingWeightPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFishingWeightPredictions(requestParameters: V1fishingPredictionApiAllFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingWeightPrediction>> {
            return localVarFp.allFishingWeightPredictions(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fishingPredictionApiFishingSpotPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingSpotPredictions(requestParameters: V1fishingPredictionApiFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<FishingSpotPrediction> {
            return localVarFp.fishingSpotPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fishingPredictionApiFishingWeightPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fishingWeightPredictions(requestParameters: V1fishingPredictionApiFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingWeightPrediction>> {
            return localVarFp.fishingWeightPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for allFishingSpotPredictions operation in V1fishingPredictionApi.
 * @export
 * @interface V1fishingPredictionApiAllFishingSpotPredictionsRequest
 */
export interface V1fishingPredictionApiAllFishingSpotPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof V1fishingPredictionApiAllFishingSpotPredictions
     */
    readonly modelId: ModelId
}

/**
 * Request parameters for allFishingWeightPredictions operation in V1fishingPredictionApi.
 * @export
 * @interface V1fishingPredictionApiAllFishingWeightPredictionsRequest
 */
export interface V1fishingPredictionApiAllFishingWeightPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof V1fishingPredictionApiAllFishingWeightPredictions
     */
    readonly modelId: ModelId
}

/**
 * Request parameters for fishingSpotPredictions operation in V1fishingPredictionApi.
 * @export
 * @interface V1fishingPredictionApiFishingSpotPredictionsRequest
 */
export interface V1fishingPredictionApiFishingSpotPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof V1fishingPredictionApiFishingSpotPredictions
     */
    readonly modelId: ModelId

    /**
     * 
     * @type {SpeciesGroup}
     * @memberof V1fishingPredictionApiFishingSpotPredictions
     */
    readonly speciesGroupId: SpeciesGroup

    /**
     * 
     * @type {string}
     * @memberof V1fishingPredictionApiFishingSpotPredictions
     */
    readonly date?: string | null
}

/**
 * Request parameters for fishingWeightPredictions operation in V1fishingPredictionApi.
 * @export
 * @interface V1fishingPredictionApiFishingWeightPredictionsRequest
 */
export interface V1fishingPredictionApiFishingWeightPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof V1fishingPredictionApiFishingWeightPredictions
     */
    readonly modelId: ModelId

    /**
     * 
     * @type {SpeciesGroup}
     * @memberof V1fishingPredictionApiFishingWeightPredictions
     */
    readonly speciesGroupId: SpeciesGroup

    /**
     * 
     * @type {string}
     * @memberof V1fishingPredictionApiFishingWeightPredictions
     */
    readonly date?: string | null

    /**
     * 
     * @type {number}
     * @memberof V1fishingPredictionApiFishingWeightPredictions
     */
    readonly limit?: number | null
}

/**
 * V1fishingPredictionApi - object-oriented interface
 * @export
 * @class V1fishingPredictionApi
 * @extends {BaseAPI}
 */
export class V1fishingPredictionApi extends BaseAPI {
    /**
     * 
     * @param {V1fishingPredictionApiAllFishingSpotPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fishingPredictionApi
     */
    public allFishingSpotPredictions(requestParameters: V1fishingPredictionApiAllFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig) {
        return V1fishingPredictionApiFp(this.configuration).allFishingSpotPredictions(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fishingPredictionApiAllFishingWeightPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fishingPredictionApi
     */
    public allFishingWeightPredictions(requestParameters: V1fishingPredictionApiAllFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig) {
        return V1fishingPredictionApiFp(this.configuration).allFishingWeightPredictions(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fishingPredictionApiFishingSpotPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fishingPredictionApi
     */
    public fishingSpotPredictions(requestParameters: V1fishingPredictionApiFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig) {
        return V1fishingPredictionApiFp(this.configuration).fishingSpotPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fishingPredictionApiFishingWeightPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fishingPredictionApi
     */
    public fishingWeightPredictions(requestParameters: V1fishingPredictionApiFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig) {
        return V1fishingPredictionApiFp(this.configuration).fishingWeightPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1fuelApi - axios parameter creator
 * @export
 */
export const V1fuelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody fuel measurements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFuelMeasurements: async (fuelMeasurementBody: Array<FuelMeasurementBody>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fuelMeasurementBody' is not null or undefined
            assertParamExists('createFuelMeasurements', 'fuelMeasurementBody', fuelMeasurementBody)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fuelMeasurementBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DeleteFuelMeasurement>} deleteFuelMeasurement fuel measurements to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFuelMeasurements: async (deleteFuelMeasurement: Array<DeleteFuelMeasurement>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFuelMeasurement' is not null or undefined
            assertParamExists('deleteFuelMeasurements', 'deleteFuelMeasurement', deleteFuelMeasurement)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFuelMeasurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuelMeasurements: async (startDate?: string | null, endDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody updated fuel measurements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuelMeasurements: async (fuelMeasurementBody: Array<FuelMeasurementBody>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fuelMeasurementBody' is not null or undefined
            assertParamExists('updateFuelMeasurements', 'fuelMeasurementBody', fuelMeasurementBody)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fuelMeasurementBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1fuelApi - functional programming interface
 * @export
 */
export const V1fuelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1fuelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody fuel measurements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFuelMeasurements(fuelMeasurementBody: Array<FuelMeasurementBody>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFuelMeasurements(fuelMeasurementBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fuelApi.createFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<DeleteFuelMeasurement>} deleteFuelMeasurement fuel measurements to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFuelMeasurements(deleteFuelMeasurement: Array<DeleteFuelMeasurement>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFuelMeasurements(deleteFuelMeasurement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fuelApi.deleteFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFuelMeasurements(startDate?: string | null, endDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FuelMeasurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFuelMeasurements(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fuelApi.getFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody updated fuel measurements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuelMeasurements(fuelMeasurementBody: Array<FuelMeasurementBody>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuelMeasurements(fuelMeasurementBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1fuelApi.updateFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1fuelApi - factory interface
 * @export
 */
export const V1fuelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1fuelApiFp(configuration)
    return {
        /**
         * 
         * @param {V1fuelApiCreateFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFuelMeasurements(requestParameters: V1fuelApiCreateFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createFuelMeasurements(requestParameters.fuelMeasurementBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fuelApiDeleteFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFuelMeasurements(requestParameters: V1fuelApiDeleteFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFuelMeasurements(requestParameters.deleteFuelMeasurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fuelApiGetFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuelMeasurements(requestParameters: V1fuelApiGetFuelMeasurementsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<FuelMeasurement>> {
            return localVarFp.getFuelMeasurements(requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1fuelApiUpdateFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuelMeasurements(requestParameters: V1fuelApiUpdateFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateFuelMeasurements(requestParameters.fuelMeasurementBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFuelMeasurements operation in V1fuelApi.
 * @export
 * @interface V1fuelApiCreateFuelMeasurementsRequest
 */
export interface V1fuelApiCreateFuelMeasurementsRequest {
    /**
     * fuel measurements
     * @type {Array<FuelMeasurementBody>}
     * @memberof V1fuelApiCreateFuelMeasurements
     */
    readonly fuelMeasurementBody: Array<FuelMeasurementBody>
}

/**
 * Request parameters for deleteFuelMeasurements operation in V1fuelApi.
 * @export
 * @interface V1fuelApiDeleteFuelMeasurementsRequest
 */
export interface V1fuelApiDeleteFuelMeasurementsRequest {
    /**
     * fuel measurements to delete
     * @type {Array<DeleteFuelMeasurement>}
     * @memberof V1fuelApiDeleteFuelMeasurements
     */
    readonly deleteFuelMeasurement: Array<DeleteFuelMeasurement>
}

/**
 * Request parameters for getFuelMeasurements operation in V1fuelApi.
 * @export
 * @interface V1fuelApiGetFuelMeasurementsRequest
 */
export interface V1fuelApiGetFuelMeasurementsRequest {
    /**
     * 
     * @type {string}
     * @memberof V1fuelApiGetFuelMeasurements
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1fuelApiGetFuelMeasurements
     */
    readonly endDate?: string | null
}

/**
 * Request parameters for updateFuelMeasurements operation in V1fuelApi.
 * @export
 * @interface V1fuelApiUpdateFuelMeasurementsRequest
 */
export interface V1fuelApiUpdateFuelMeasurementsRequest {
    /**
     * updated fuel measurements
     * @type {Array<FuelMeasurementBody>}
     * @memberof V1fuelApiUpdateFuelMeasurements
     */
    readonly fuelMeasurementBody: Array<FuelMeasurementBody>
}

/**
 * V1fuelApi - object-oriented interface
 * @export
 * @class V1fuelApi
 * @extends {BaseAPI}
 */
export class V1fuelApi extends BaseAPI {
    /**
     * 
     * @param {V1fuelApiCreateFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fuelApi
     */
    public createFuelMeasurements(requestParameters: V1fuelApiCreateFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return V1fuelApiFp(this.configuration).createFuelMeasurements(requestParameters.fuelMeasurementBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fuelApiDeleteFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fuelApi
     */
    public deleteFuelMeasurements(requestParameters: V1fuelApiDeleteFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return V1fuelApiFp(this.configuration).deleteFuelMeasurements(requestParameters.deleteFuelMeasurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fuelApiGetFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fuelApi
     */
    public getFuelMeasurements(requestParameters: V1fuelApiGetFuelMeasurementsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1fuelApiFp(this.configuration).getFuelMeasurements(requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1fuelApiUpdateFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1fuelApi
     */
    public updateFuelMeasurements(requestParameters: V1fuelApiUpdateFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return V1fuelApiFp(this.configuration).updateFuelMeasurements(requestParameters.fuelMeasurementBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1gearApi - axios parameter creator
 * @export
 */
export const V1gearApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gear: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gearGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gearMainGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear_main_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1gearApi - functional programming interface
 * @export
 */
export const V1gearApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1gearApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gear(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gear(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1gearApi.gear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gearGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gearGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1gearApi.gearGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gearMainGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearMainGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gearMainGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1gearApi.gearMainGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1gearApi - factory interface
 * @export
 */
export const V1gearApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1gearApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gear(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearDetailed>> {
            return localVarFp.gear(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gearGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearGroupDetailed>> {
            return localVarFp.gearGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gearMainGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearMainGroupDetailed>> {
            return localVarFp.gearMainGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1gearApi - object-oriented interface
 * @export
 * @class V1gearApi
 * @extends {BaseAPI}
 */
export class V1gearApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1gearApi
     */
    public gear(options?: RawAxiosRequestConfig) {
        return V1gearApiFp(this.configuration).gear(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1gearApi
     */
    public gearGroups(options?: RawAxiosRequestConfig) {
        return V1gearApiFp(this.configuration).gearGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1gearApi
     */
    public gearMainGroups(options?: RawAxiosRequestConfig) {
        return V1gearApiFp(this.configuration).gearMainGroups(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1haulApi - axios parameter creator
 * @export
 */
export const V1haulApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {HaulsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hauls: async (months?: Array<string>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: HaulsSorting | null, ordering?: Ordering | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/hauls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActiveHaulsFilter} activeFilter 
         * @param {Array<number>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {number | null} [bycatchPercentage] 
         * @param {boolean | null} [majoritySpeciesGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haulsMatrix: async (activeFilter: ActiveHaulsFilter, months?: Array<number>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, bycatchPercentage?: number | null, majoritySpeciesGroup?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeFilter' is not null or undefined
            assertParamExists('haulsMatrix', 'activeFilter', activeFilter)
            const localVarPath = `/v1.0/hauls_matrix/{active_filter}`
                .replace(`{${"active_filter"}}`, encodeURIComponent(String(activeFilter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (bycatchPercentage !== undefined) {
                localVarQueryParameter['bycatchPercentage'] = bycatchPercentage;
            }

            if (majoritySpeciesGroup !== undefined) {
                localVarQueryParameter['majoritySpeciesGroup'] = majoritySpeciesGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1haulApi - functional programming interface
 * @export
 */
export const V1haulApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1haulApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {HaulsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hauls(months?: Array<string>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: HaulsSorting | null, ordering?: Ordering | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Haul>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hauls(months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, sorting, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1haulApi.hauls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ActiveHaulsFilter} activeFilter 
         * @param {Array<number>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {number | null} [bycatchPercentage] 
         * @param {boolean | null} [majoritySpeciesGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async haulsMatrix(activeFilter: ActiveHaulsFilter, months?: Array<number>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, bycatchPercentage?: number | null, majoritySpeciesGroup?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HaulsMatrix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.haulsMatrix(activeFilter, months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, bycatchPercentage, majoritySpeciesGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1haulApi.haulsMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1haulApi - factory interface
 * @export
 */
export const V1haulApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1haulApiFp(configuration)
    return {
        /**
         * 
         * @param {V1haulApiHaulsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hauls(requestParameters: V1haulApiHaulsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Haul>> {
            return localVarFp.hauls(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1haulApiHaulsMatrixRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haulsMatrix(requestParameters: V1haulApiHaulsMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<HaulsMatrix> {
            return localVarFp.haulsMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.bycatchPercentage, requestParameters.majoritySpeciesGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hauls operation in V1haulApi.
 * @export
 * @interface V1haulApiHaulsRequest
 */
export interface V1haulApiHaulsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1haulApiHauls
     */
    readonly months?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof V1haulApiHauls
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof V1haulApiHauls
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof V1haulApiHauls
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof V1haulApiHauls
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1haulApiHauls
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {HaulsSorting}
     * @memberof V1haulApiHauls
     */
    readonly sorting?: HaulsSorting | null

    /**
     * 
     * @type {Ordering}
     * @memberof V1haulApiHauls
     */
    readonly ordering?: Ordering | null
}

/**
 * Request parameters for haulsMatrix operation in V1haulApi.
 * @export
 * @interface V1haulApiHaulsMatrixRequest
 */
export interface V1haulApiHaulsMatrixRequest {
    /**
     * 
     * @type {ActiveHaulsFilter}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly activeFilter: ActiveHaulsFilter

    /**
     * 
     * @type {Array<number>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly months?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly bycatchPercentage?: number | null

    /**
     * 
     * @type {boolean}
     * @memberof V1haulApiHaulsMatrix
     */
    readonly majoritySpeciesGroup?: boolean | null
}

/**
 * V1haulApi - object-oriented interface
 * @export
 * @class V1haulApi
 * @extends {BaseAPI}
 */
export class V1haulApi extends BaseAPI {
    /**
     * 
     * @param {V1haulApiHaulsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1haulApi
     */
    public hauls(requestParameters: V1haulApiHaulsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1haulApiFp(this.configuration).hauls(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1haulApiHaulsMatrixRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1haulApi
     */
    public haulsMatrix(requestParameters: V1haulApiHaulsMatrixRequest, options?: RawAxiosRequestConfig) {
        return V1haulApiFp(this.configuration).haulsMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.bycatchPercentage, requestParameters.majoritySpeciesGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1landingApi - axios parameter creator
 * @export
 */
export const V1landingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveLandingFilter} activeFilter 
         * @param {Array<number>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingMatrix: async (activeFilter: ActiveLandingFilter, months?: Array<number>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeFilter' is not null or undefined
            assertParamExists('landingMatrix', 'activeFilter', activeFilter)
            const localVarPath = `/v1.0/landing_matrix/{active_filter}`
                .replace(`{${"active_filter"}}`, encodeURIComponent(String(activeFilter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {LandingsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landings: async (months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: LandingsSorting | null, ordering?: Ordering | null, limit?: number | null, offset?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/landings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1landingApi - functional programming interface
 * @export
 */
export const V1landingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1landingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActiveLandingFilter} activeFilter 
         * @param {Array<number>} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landingMatrix(activeFilter: ActiveLandingFilter, months?: Array<number>, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingMatrix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landingMatrix(activeFilter, months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1landingApi.landingMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {LandingsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landings(months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: LandingsSorting | null, ordering?: Ordering | null, limit?: number | null, offset?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Landing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landings(months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, sorting, ordering, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1landingApi.landings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1landingApi - factory interface
 * @export
 */
export const V1landingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1landingApiFp(configuration)
    return {
        /**
         * 
         * @param {V1landingApiLandingMatrixRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landingMatrix(requestParameters: V1landingApiLandingMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<LandingMatrix> {
            return localVarFp.landingMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1landingApiLandingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landings(requestParameters: V1landingApiLandingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Landing>> {
            return localVarFp.landings(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for landingMatrix operation in V1landingApi.
 * @export
 * @interface V1landingApiLandingMatrixRequest
 */
export interface V1landingApiLandingMatrixRequest {
    /**
     * 
     * @type {ActiveLandingFilter}
     * @memberof V1landingApiLandingMatrix
     */
    readonly activeFilter: ActiveLandingFilter

    /**
     * 
     * @type {Array<number>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly months?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1landingApiLandingMatrix
     */
    readonly fiskeridirVesselIds?: Array<number> | null
}

/**
 * Request parameters for landings operation in V1landingApi.
 * @export
 * @interface V1landingApiLandingsRequest
 */
export interface V1landingApiLandingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1landingApiLandings
     */
    readonly months?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1landingApiLandings
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof V1landingApiLandings
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof V1landingApiLandings
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof V1landingApiLandings
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1landingApiLandings
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {LandingsSorting}
     * @memberof V1landingApiLandings
     */
    readonly sorting?: LandingsSorting | null

    /**
     * 
     * @type {Ordering}
     * @memberof V1landingApiLandings
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {number}
     * @memberof V1landingApiLandings
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof V1landingApiLandings
     */
    readonly offset?: number | null
}

/**
 * V1landingApi - object-oriented interface
 * @export
 * @class V1landingApi
 * @extends {BaseAPI}
 */
export class V1landingApi extends BaseAPI {
    /**
     * 
     * @param {V1landingApiLandingMatrixRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1landingApi
     */
    public landingMatrix(requestParameters: V1landingApiLandingMatrixRequest, options?: RawAxiosRequestConfig) {
        return V1landingApiFp(this.configuration).landingMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1landingApiLandingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1landingApi
     */
    public landings(requestParameters: V1landingApiLandingsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1landingApiFp(this.configuration).landings(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1speciesApi - axios parameter creator
 * @export
 */
export const V1speciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        species: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesFao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_fao`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesFiskeridir: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_fiskeridir`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean | null} [hasMlModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesGroups: async (hasMlModels?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (hasMlModels !== undefined) {
                localVarQueryParameter['hasMlModels'] = hasMlModels;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesMainGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_main_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1speciesApi - functional programming interface
 * @export
 */
export const V1speciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1speciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async species(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Species>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.species(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1speciesApi.species']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speciesFao(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesFao>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speciesFao(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1speciesApi.speciesFao']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speciesFiskeridir(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesFiskeridir>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speciesFiskeridir(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1speciesApi.speciesFiskeridir']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean | null} [hasMlModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speciesGroups(hasMlModels?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speciesGroups(hasMlModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1speciesApi.speciesGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speciesMainGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesMainGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speciesMainGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1speciesApi.speciesMainGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1speciesApi - factory interface
 * @export
 */
export const V1speciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1speciesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        species(options?: RawAxiosRequestConfig): AxiosPromise<Array<Species>> {
            return localVarFp.species(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesFao(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesFao>> {
            return localVarFp.speciesFao(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesFiskeridir(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesFiskeridir>> {
            return localVarFp.speciesFiskeridir(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1speciesApiSpeciesGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesGroups(requestParameters: V1speciesApiSpeciesGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesGroupDetailed>> {
            return localVarFp.speciesGroups(requestParameters.hasMlModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speciesMainGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesMainGroupDetailed>> {
            return localVarFp.speciesMainGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for speciesGroups operation in V1speciesApi.
 * @export
 * @interface V1speciesApiSpeciesGroupsRequest
 */
export interface V1speciesApiSpeciesGroupsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof V1speciesApiSpeciesGroups
     */
    readonly hasMlModels?: boolean | null
}

/**
 * V1speciesApi - object-oriented interface
 * @export
 * @class V1speciesApi
 * @extends {BaseAPI}
 */
export class V1speciesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1speciesApi
     */
    public species(options?: RawAxiosRequestConfig) {
        return V1speciesApiFp(this.configuration).species(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1speciesApi
     */
    public speciesFao(options?: RawAxiosRequestConfig) {
        return V1speciesApiFp(this.configuration).speciesFao(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1speciesApi
     */
    public speciesFiskeridir(options?: RawAxiosRequestConfig) {
        return V1speciesApiFp(this.configuration).speciesFiskeridir(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1speciesApiSpeciesGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1speciesApi
     */
    public speciesGroups(requestParameters: V1speciesApiSpeciesGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1speciesApiFp(this.configuration).speciesGroups(requestParameters.hasMlModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1speciesApi
     */
    public speciesMainGroups(options?: RawAxiosRequestConfig) {
        return V1speciesApiFp(this.configuration).speciesMainGroups(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1tripApi - axios parameter creator
 * @export
 */
export const V1tripApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} fiskeridirVesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentTrip: async (fiskeridirVesselId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiskeridirVesselId' is not null or undefined
            assertParamExists('currentTrip', 'fiskeridirVesselId', fiskeridirVesselId)
            const localVarPath = `/v1.0/trips/current/{fiskeridir_vessel_id}`
                .replace(`{${"fiskeridir_vessel_id"}}`, encodeURIComponent(String(fiskeridirVesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} haulId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOfHaul: async (haulId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'haulId' is not null or undefined
            assertParamExists('tripOfHaul', 'haulId', haulId)
            const localVarPath = `/v1.0/trip_of_haul/{haul_id}`
                .replace(`{${"haul_id"}}`, encodeURIComponent(String(haulId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} landingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOfLanding: async (landingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landingId' is not null or undefined
            assertParamExists('tripOfLanding', 'landingId', landingId)
            const localVarPath = `/v1.0/trip_of_landing/{landing_id}`
                .replace(`{${"landing_id"}}`, encodeURIComponent(String(landingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {Array<string> | null} [deliveryPoints] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {number | null} [minWeight] 
         * @param {number | null} [maxWeight] 
         * @param {TripSorting | null} [sorting] 
         * @param {Array<string> | null} [gearGroupIds] 
         * @param {Array<string> | null} [speciesGroupIds] 
         * @param {Array<string> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trips: async (limit?: number | null, offset?: number | null, ordering?: Ordering | null, deliveryPoints?: Array<string> | null, startDate?: string | null, endDate?: string | null, minWeight?: number | null, maxWeight?: number | null, sorting?: TripSorting | null, gearGroupIds?: Array<string> | null, speciesGroupIds?: Array<string> | null, vesselLengthGroups?: Array<string> | null, fiskeridirVesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (deliveryPoints) {
                localVarQueryParameter['deliveryPoints[]'] = deliveryPoints;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (minWeight !== undefined) {
                localVarQueryParameter['minWeight'] = minWeight;
            }

            if (maxWeight !== undefined) {
                localVarQueryParameter['maxWeight'] = maxWeight;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1tripApi - functional programming interface
 * @export
 */
export const V1tripApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1tripApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} fiskeridirVesselId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentTrip(fiskeridirVesselId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentTrip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentTrip(fiskeridirVesselId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripApi.currentTrip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} haulId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripOfHaul(haulId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripOfHaul(haulId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripApi.tripOfHaul']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} landingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripOfLanding(landingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripOfLanding(landingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripApi.tripOfLanding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {Array<string> | null} [deliveryPoints] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {number | null} [minWeight] 
         * @param {number | null} [maxWeight] 
         * @param {TripSorting | null} [sorting] 
         * @param {Array<string> | null} [gearGroupIds] 
         * @param {Array<string> | null} [speciesGroupIds] 
         * @param {Array<string> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trips(limit?: number | null, offset?: number | null, ordering?: Ordering | null, deliveryPoints?: Array<string> | null, startDate?: string | null, endDate?: string | null, minWeight?: number | null, maxWeight?: number | null, sorting?: TripSorting | null, gearGroupIds?: Array<string> | null, speciesGroupIds?: Array<string> | null, vesselLengthGroups?: Array<string> | null, fiskeridirVesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trips(limit, offset, ordering, deliveryPoints, startDate, endDate, minWeight, maxWeight, sorting, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripApi.trips']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1tripApi - factory interface
 * @export
 */
export const V1tripApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1tripApiFp(configuration)
    return {
        /**
         * 
         * @param {V1tripApiCurrentTripRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentTrip(requestParameters: V1tripApiCurrentTripRequest, options?: RawAxiosRequestConfig): AxiosPromise<CurrentTrip> {
            return localVarFp.currentTrip(requestParameters.fiskeridirVesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1tripApiTripOfHaulRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOfHaul(requestParameters: V1tripApiTripOfHaulRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trip> {
            return localVarFp.tripOfHaul(requestParameters.haulId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1tripApiTripOfLandingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripOfLanding(requestParameters: V1tripApiTripOfLandingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trip> {
            return localVarFp.tripOfLanding(requestParameters.landingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1tripApiTripsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trips(requestParameters: V1tripApiTripsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Trip>> {
            return localVarFp.trips(requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.deliveryPoints, requestParameters.startDate, requestParameters.endDate, requestParameters.minWeight, requestParameters.maxWeight, requestParameters.sorting, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for currentTrip operation in V1tripApi.
 * @export
 * @interface V1tripApiCurrentTripRequest
 */
export interface V1tripApiCurrentTripRequest {
    /**
     * 
     * @type {number}
     * @memberof V1tripApiCurrentTrip
     */
    readonly fiskeridirVesselId: number
}

/**
 * Request parameters for tripOfHaul operation in V1tripApi.
 * @export
 * @interface V1tripApiTripOfHaulRequest
 */
export interface V1tripApiTripOfHaulRequest {
    /**
     * 
     * @type {number}
     * @memberof V1tripApiTripOfHaul
     */
    readonly haulId: number
}

/**
 * Request parameters for tripOfLanding operation in V1tripApi.
 * @export
 * @interface V1tripApiTripOfLandingRequest
 */
export interface V1tripApiTripOfLandingRequest {
    /**
     * 
     * @type {string}
     * @memberof V1tripApiTripOfLanding
     */
    readonly landingId: string
}

/**
 * Request parameters for trips operation in V1tripApi.
 * @export
 * @interface V1tripApiTripsRequest
 */
export interface V1tripApiTripsRequest {
    /**
     * 
     * @type {number}
     * @memberof V1tripApiTrips
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof V1tripApiTrips
     */
    readonly offset?: number | null

    /**
     * 
     * @type {Ordering}
     * @memberof V1tripApiTrips
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1tripApiTrips
     */
    readonly deliveryPoints?: Array<string> | null

    /**
     * 
     * @type {string}
     * @memberof V1tripApiTrips
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1tripApiTrips
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {number}
     * @memberof V1tripApiTrips
     */
    readonly minWeight?: number | null

    /**
     * 
     * @type {number}
     * @memberof V1tripApiTrips
     */
    readonly maxWeight?: number | null

    /**
     * 
     * @type {TripSorting}
     * @memberof V1tripApiTrips
     */
    readonly sorting?: TripSorting | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1tripApiTrips
     */
    readonly gearGroupIds?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1tripApiTrips
     */
    readonly speciesGroupIds?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof V1tripApiTrips
     */
    readonly vesselLengthGroups?: Array<string> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1tripApiTrips
     */
    readonly fiskeridirVesselIds?: Array<number> | null
}

/**
 * V1tripApi - object-oriented interface
 * @export
 * @class V1tripApi
 * @extends {BaseAPI}
 */
export class V1tripApi extends BaseAPI {
    /**
     * 
     * @param {V1tripApiCurrentTripRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripApi
     */
    public currentTrip(requestParameters: V1tripApiCurrentTripRequest, options?: RawAxiosRequestConfig) {
        return V1tripApiFp(this.configuration).currentTrip(requestParameters.fiskeridirVesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1tripApiTripOfHaulRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripApi
     */
    public tripOfHaul(requestParameters: V1tripApiTripOfHaulRequest, options?: RawAxiosRequestConfig) {
        return V1tripApiFp(this.configuration).tripOfHaul(requestParameters.haulId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1tripApiTripOfLandingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripApi
     */
    public tripOfLanding(requestParameters: V1tripApiTripOfLandingRequest, options?: RawAxiosRequestConfig) {
        return V1tripApiFp(this.configuration).tripOfLanding(requestParameters.landingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1tripApiTripsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripApi
     */
    public trips(requestParameters: V1tripApiTripsRequest = {}, options?: RawAxiosRequestConfig) {
        return V1tripApiFp(this.configuration).trips(requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.deliveryPoints, requestParameters.startDate, requestParameters.endDate, requestParameters.minWeight, requestParameters.maxWeight, requestParameters.sorting, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1tripBenchmarkApi - axios parameter creator
 * @export
 */
export const V1tripBenchmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        average: async (startDate: string, endDate: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('average', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('average', 'endDate', endDate)
            const localVarPath = `/v1.0/trip_benchmarks/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (gearGroups) {
                localVarQueryParameter['gearGroups[]'] = gearGroups;
            }

            if (lengthGroup !== undefined) {
                localVarQueryParameter['lengthGroup'] = lengthGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripBenchmarks: async (startDate?: string | null, endDate?: string | null, ordering?: Ordering | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/trip_benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1tripBenchmarkApi - functional programming interface
 * @export
 */
export const V1tripBenchmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1tripBenchmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async average(startDate: string, endDate: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AverageTripBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.average(startDate, endDate, gearGroups, lengthGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripBenchmarkApi.average']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripBenchmarks(startDate?: string | null, endDate?: string | null, ordering?: Ordering | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TripBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripBenchmarks(startDate, endDate, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1tripBenchmarkApi.tripBenchmarks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1tripBenchmarkApi - factory interface
 * @export
 */
export const V1tripBenchmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1tripBenchmarkApiFp(configuration)
    return {
        /**
         * 
         * @param {V1tripBenchmarkApiAverageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        average(requestParameters: V1tripBenchmarkApiAverageRequest, options?: RawAxiosRequestConfig): AxiosPromise<AverageTripBenchmarks> {
            return localVarFp.average(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1tripBenchmarkApiTripBenchmarksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripBenchmarks(requestParameters: V1tripBenchmarkApiTripBenchmarksRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TripBenchmarks> {
            return localVarFp.tripBenchmarks(requestParameters.startDate, requestParameters.endDate, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for average operation in V1tripBenchmarkApi.
 * @export
 * @interface V1tripBenchmarkApiAverageRequest
 */
export interface V1tripBenchmarkApiAverageRequest {
    /**
     * 
     * @type {string}
     * @memberof V1tripBenchmarkApiAverage
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof V1tripBenchmarkApiAverage
     */
    readonly endDate: string

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof V1tripBenchmarkApiAverage
     */
    readonly gearGroups?: Array<GearGroup> | null

    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof V1tripBenchmarkApiAverage
     */
    readonly lengthGroup?: VesselLengthGroup | null
}

/**
 * Request parameters for tripBenchmarks operation in V1tripBenchmarkApi.
 * @export
 * @interface V1tripBenchmarkApiTripBenchmarksRequest
 */
export interface V1tripBenchmarkApiTripBenchmarksRequest {
    /**
     * 
     * @type {string}
     * @memberof V1tripBenchmarkApiTripBenchmarks
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1tripBenchmarkApiTripBenchmarks
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {Ordering}
     * @memberof V1tripBenchmarkApiTripBenchmarks
     */
    readonly ordering?: Ordering | null
}

/**
 * V1tripBenchmarkApi - object-oriented interface
 * @export
 * @class V1tripBenchmarkApi
 * @extends {BaseAPI}
 */
export class V1tripBenchmarkApi extends BaseAPI {
    /**
     * 
     * @param {V1tripBenchmarkApiAverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripBenchmarkApi
     */
    public average(requestParameters: V1tripBenchmarkApiAverageRequest, options?: RawAxiosRequestConfig) {
        return V1tripBenchmarkApiFp(this.configuration).average(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1tripBenchmarkApiTripBenchmarksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1tripBenchmarkApi
     */
    public tripBenchmarks(requestParameters: V1tripBenchmarkApiTripBenchmarksRequest = {}, options?: RawAxiosRequestConfig) {
        return V1tripBenchmarkApiFp(this.configuration).tripBenchmarks(requestParameters.startDate, requestParameters.endDate, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1userApi - axios parameter creator
 * @export
 */
export const V1userApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user updated user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/v1.0/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1userApi - functional programming interface
 * @export
 */
export const V1userApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1userApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1userApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {User} user updated user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1userApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1userApi - factory interface
 * @export
 */
export const V1userApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1userApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1userApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: V1userApiUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(requestParameters.user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateUser operation in V1userApi.
 * @export
 * @interface V1userApiUpdateUserRequest
 */
export interface V1userApiUpdateUserRequest {
    /**
     * updated user information
     * @type {User}
     * @memberof V1userApiUpdateUser
     */
    readonly user: User
}

/**
 * V1userApi - object-oriented interface
 * @export
 * @class V1userApi
 * @extends {BaseAPI}
 */
export class V1userApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public getUser(options?: RawAxiosRequestConfig) {
        return V1userApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1userApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1userApi
     */
    public updateUser(requestParameters: V1userApiUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return V1userApiFp(this.configuration).updateUser(requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1vesselApi - axios parameter creator
 * @export
 */
export const V1vesselApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselBenchmarks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/vessels/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vessels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/vessels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1vesselApi - functional programming interface
 * @export
 */
export const V1vesselApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1vesselApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vesselBenchmarks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vesselBenchmarks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1vesselApi.vesselBenchmarks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vessels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vessel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vessels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1vesselApi.vessels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1vesselApi - factory interface
 * @export
 */
export const V1vesselApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1vesselApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vesselBenchmarks(options?: RawAxiosRequestConfig): AxiosPromise<VesselBenchmarks> {
            return localVarFp.vesselBenchmarks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vessels(options?: RawAxiosRequestConfig): AxiosPromise<Array<Vessel>> {
            return localVarFp.vessels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1vesselApi - object-oriented interface
 * @export
 * @class V1vesselApi
 * @extends {BaseAPI}
 */
export class V1vesselApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1vesselApi
     */
    public vesselBenchmarks(options?: RawAxiosRequestConfig) {
        return V1vesselApiFp(this.configuration).vesselBenchmarks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1vesselApi
     */
    public vessels(options?: RawAxiosRequestConfig) {
        return V1vesselApiFp(this.configuration).vessels(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1vmsApi - axios parameter creator
 * @export
 */
export const V1vmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} callSign 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vmsPositions: async (callSign: string, start?: string | null, end?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callSign' is not null or undefined
            assertParamExists('vmsPositions', 'callSign', callSign)
            const localVarPath = `/v1.0/vms/{call_sign}`
                .replace(`{${"call_sign"}}`, encodeURIComponent(String(callSign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1vmsApi - functional programming interface
 * @export
 */
export const V1vmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1vmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} callSign 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vmsPositions(callSign: string, start?: string | null, end?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VmsPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vmsPositions(callSign, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1vmsApi.vmsPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1vmsApi - factory interface
 * @export
 */
export const V1vmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1vmsApiFp(configuration)
    return {
        /**
         * 
         * @param {V1vmsApiVmsPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vmsPositions(requestParameters: V1vmsApiVmsPositionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VmsPosition>> {
            return localVarFp.vmsPositions(requestParameters.callSign, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for vmsPositions operation in V1vmsApi.
 * @export
 * @interface V1vmsApiVmsPositionsRequest
 */
export interface V1vmsApiVmsPositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof V1vmsApiVmsPositions
     */
    readonly callSign: string

    /**
     * 
     * @type {string}
     * @memberof V1vmsApiVmsPositions
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1vmsApiVmsPositions
     */
    readonly end?: string | null
}

/**
 * V1vmsApi - object-oriented interface
 * @export
 * @class V1vmsApi
 * @extends {BaseAPI}
 */
export class V1vmsApi extends BaseAPI {
    /**
     * 
     * @param {V1vmsApiVmsPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1vmsApi
     */
    public vmsPositions(requestParameters: V1vmsApiVmsPositionsRequest, options?: RawAxiosRequestConfig) {
        return V1vmsApiFp(this.configuration).vmsPositions(requestParameters.callSign, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1weatherApi - axios parameter creator
 * @export
 */
export const V1weatherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weather: async (startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/weather`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (weatherLocationIds) {
                localVarQueryParameter['weatherLocationIds[]'] = weatherLocationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherLocations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/weather_locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1weatherApi - functional programming interface
 * @export
 */
export const V1weatherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1weatherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weather(startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Weather>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weather(startDate, endDate, weatherLocationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1weatherApi.weather']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherLocations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeatherLocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherLocations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1weatherApi.weatherLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1weatherApi - factory interface
 * @export
 */
export const V1weatherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1weatherApiFp(configuration)
    return {
        /**
         * 
         * @param {V1weatherApiWeatherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weather(requestParameters: V1weatherApiWeatherRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Weather>> {
            return localVarFp.weather(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherLocations(options?: RawAxiosRequestConfig): AxiosPromise<Array<WeatherLocation>> {
            return localVarFp.weatherLocations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for weather operation in V1weatherApi.
 * @export
 * @interface V1weatherApiWeatherRequest
 */
export interface V1weatherApiWeatherRequest {
    /**
     * 
     * @type {string}
     * @memberof V1weatherApiWeather
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof V1weatherApiWeather
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {Array<number>}
     * @memberof V1weatherApiWeather
     */
    readonly weatherLocationIds?: Array<number> | null
}

/**
 * V1weatherApi - object-oriented interface
 * @export
 * @class V1weatherApi
 * @extends {BaseAPI}
 */
export class V1weatherApi extends BaseAPI {
    /**
     * 
     * @param {V1weatherApiWeatherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1weatherApi
     */
    public weather(requestParameters: V1weatherApiWeatherRequest = {}, options?: RawAxiosRequestConfig) {
        return V1weatherApiFp(this.configuration).weather(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1weatherApi
     */
    public weatherLocations(options?: RawAxiosRequestConfig) {
        return V1weatherApiFp(this.configuration).weatherLocations(options).then((request) => request(this.axios, this.basePath));
    }
}



